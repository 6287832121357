import { render, staticRenderFns } from "./ContactInformationMainContact.vue?vue&type=template&id=007cee55&scoped=true&"
import script from "./ContactInformationMainContact.vue?vue&type=script&lang=ts&"
export * from "./ContactInformationMainContact.vue?vue&type=script&lang=ts&"
import style0 from "./ContactInformationMainContact.vue?vue&type=style&index=0&id=007cee55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007cee55",
  null
  
)

export default component.exports