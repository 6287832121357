var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"additional-questions"},[_c('div',{class:{
      questions: _vm.isMultipleProfiles,
      'question-yesno': !_vm.isMultipleProfiles,
    }},[_c('div',{staticClass:"question"},[_vm._v("\n      "+_vm._s(_vm.question.additionalDataQuestion)+"\n    ")]),_vm._v(" "),_vm._l((_vm.profiles),function(profile,profileIndex){return [_c('div',{key:profileIndex,staticClass:"answer",class:{
          mobile: _vm.isMobile,
          textarea: _vm.question.answerType === _vm.additionalDataGrossListEnum.TEXT,
          'p-2': _vm.question.answerType !== _vm.additionalDataGrossListEnum.YESNO && !_vm.isRevisionHistoryViewMode,
          'red-border': _vm.showYesNoQuestionWarning̣(_vm.question, profile),
        }},[(profile.additionals[_vm.question.additionalDataId] != undefined)?[(_vm.isRevisionHistoryViewMode)?[(
                profile.additionals[_vm.question.additionalDataId].updaterName &&
                profile.additionals[_vm.question.additionalDataId].updatedAt
              )?[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('ADDITIONAL_DATA_EDITED_BY'))+"\n              "),_c('b',[_vm._v(_vm._s(profile.additionals[_vm.question.additionalDataId].updaterName)+"\n                "),(profile.additionals[_vm.question.additionalDataId].updaterInstitutionRole != null)?[_vm._v("\n                  ("+_vm._s(_vm._f("fromTextKey")(('INSTITUTION_ROLE_' +
                      profile.additionals[_vm.question.additionalDataId].updaterInstitutionRole
                        .replace('-', '')
                        .toUpperCase())))+")")]:_vm._e()],2),_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('ADDITIONAL_DATA_EDITED_AT'))+"\n              "),_c('b',[_vm._v(_vm._s(profile.additionals[_vm.question.additionalDataId].updatedAt))])]:_vm._e()]:[(_vm.question.answerType == _vm.additionalDataGrossListEnum.YESNO)?[(!_vm.isDisabled)?_c('b-form-radio-group',{attrs:{"disabled":!_vm.isAbleToEdit(profile.additionals[_vm.question.additionalDataId].canCurrentProfileRespond),"required":""},on:{"input":function($event){return _vm.showQuestionYesNoMultipleProfiles(_vm.question.additionalDataId, profile, $event)},"change":function($event){return _vm.showQuestionYesNoMultipleProfiles(_vm.question.additionalDataId, profile, $event)}},model:{value:(profile.additionals[_vm.question.additionalDataId].yesNoResponse),callback:function ($$v) {_vm.$set(profile.additionals[_vm.question.additionalDataId], "yesNoResponse", $$v)},expression:"profile.additionals[question.additionalDataId].yesNoResponse"}},[_c('b-form-radio',{staticClass:"checked-is-disabled",attrs:{"value":"yes"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('YES')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_YES',{
                        question: _vm.question.additionalDataQuestion,
                        profileName: profile.institutionProfile.name,
                        institutionName: profile.institutionProfile.institutionName,
                      })))])]),_vm._v(" "),_c('b-form-radio',{staticClass:"checked-is-disabled",attrs:{"value":"no"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm._f("fromTextKey")('NO')))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_CHECK_NO',{
                        question: _vm.question.additionalDataQuestion,
                        profileName: profile.institutionProfile.name,
                        institutionName: profile.institutionProfile.institutionName,
                      })))])])],1):_c('div',{staticClass:"answer-text"},[_vm._v("\n                "+_vm._s(_vm._f("fromTextKey")(('ADDITIONAL_ANSWER_' + profile.additionals[_vm.question.additionalDataId].yesNoResponse).toUpperCase()))+"\n              ")])]:(_vm.question.answerType == _vm.additionalDataGrossListEnum.PHONENUMBER)?[_c('b-form-input',{staticClass:"answer-input",class:{
                  'red-border': _vm.showPhoneQuestionWarning(_vm.question, profile),
                },attrs:{"placeholder":_vm._f("fromTextKey")('PLACEHOLDER_PHONE'),"disabled":!_vm.isAbleToEdit(profile.additionals[_vm.question.additionalDataId].canCurrentProfileRespond),"type":"tel"},model:{value:(profile.additionals[_vm.question.additionalDataId].response),callback:function ($$v) {_vm.$set(profile.additionals[_vm.question.additionalDataId], "response", $$v)},expression:"profile.additionals[question.additionalDataId].response"}})]:[_c('b-form-textarea',{staticClass:"answer-textarea-input",class:{
                  'red-border': _vm.showTextQuestionWarning̣(_vm.question, profile),
                },attrs:{"placeholder":_vm._f("fromTextKey")('PLACEHOLDER_TEXT'),"aria-label":_vm.question.additionalDataQuestion,"disabled":!_vm.isAbleToEdit(profile.additionals[_vm.question.additionalDataId].canCurrentProfileRespond),"rows":"3"},model:{value:(profile.additionals[_vm.question.additionalDataId].response),callback:function ($$v) {_vm.$set(profile.additionals[_vm.question.additionalDataId], "response", $$v)},expression:"profile.additionals[question.additionalDataId].response"}})]]]:_vm._e()],2)]})],2),_vm._v(" "),(!_vm.isRevisionHistoryViewMode && _vm.question.showOptionQuestionYes)?_c('div',{staticClass:"questions optional-question"},[_c('div',{staticClass:"question optional-question"},[_vm._v("\n      "+_vm._s(_vm.question.optionalQuestionForYes)+"\n    ")]),_vm._v(" "),_vm._l((_vm.profiles),function(profile,profileIndex){return [_c('div',{key:profileIndex,staticClass:"answer p-2",class:{
          disabled:
            profile.additionals != null &&
            profile.additionals[_vm.question.additionalDataId] != undefined &&
            profile.additionals[_vm.question.additionalDataId].yesNoResponse != 'yes',
        }},[(
            profile.additionals[_vm.question.additionalDataId] != undefined &&
            profile.additionals[_vm.question.additionalDataId].yesNoResponse == 'yes'
          )?_c('b-form-textarea',{staticClass:"answer-textarea-input",attrs:{"disabled":!_vm.isAbleToEdit(profile.additionals[_vm.question.additionalDataId].canCurrentProfileRespond),"placeholder":_vm._f("fromTextKey")('PLACEHOLDER_TEXT'),"aria-label":_vm.question.additionalDataQuestion},model:{value:(profile.additionals[_vm.question.additionalDataId].responding),callback:function ($$v) {_vm.$set(profile.additionals[_vm.question.additionalDataId], "responding", $$v)},expression:"profile.additionals[question.additionalDataId].responding"}}):_vm._e()],1)]})],2):_vm._e(),_vm._v(" "),(!_vm.isRevisionHistoryViewMode && _vm.question.showOptionQuestionNo)?_c('div',{staticClass:"questions optional-question"},[_c('div',{staticClass:"question optional-question"},[_vm._v("\n      "+_vm._s(_vm.question.optionalQuestionForNo)+"\n    ")]),_vm._v(" "),_vm._l((_vm.profiles),function(profile,profileIndex){return [_c('div',{key:profileIndex,staticClass:"answer p-2",class:{
          disabled:
            profile.additionals != null &&
            profile.additionals[_vm.question.additionalDataId] != undefined &&
            profile.additionals[_vm.question.additionalDataId].yesNoResponse != 'no',
        }},[(
            profile.additionals[_vm.question.additionalDataId] != undefined &&
            profile.additionals[_vm.question.additionalDataId].yesNoResponse == 'no'
          )?_c('b-form-textarea',{staticClass:"answer-textarea-input",attrs:{"disabled":!_vm.isAbleToEdit(profile.additionals[_vm.question.additionalDataId].canCurrentProfileRespond),"placeholder":_vm._f("fromTextKey")('PLACEHOLDER_TEXT'),"aria-label":_vm.question.additionalDataQuestion},model:{value:(profile.additionals[_vm.question.additionalDataId].responding),callback:function ($$v) {_vm.$set(profile.additionals[_vm.question.additionalDataId], "responding", $$v)},expression:"profile.additionals[question.additionalDataId].responding"}}):_vm._e()],1)]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }