import { render, staticRenderFns } from "./AdditionalQuestionForProfiles.vue?vue&type=template&id=796c0c5d&scoped=true&"
import script from "./AdditionalQuestionForProfiles.vue?vue&type=script&lang=js&"
export * from "./AdditionalQuestionForProfiles.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalQuestionForProfiles.vue?vue&type=style&index=0&id=796c0c5d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796c0c5d",
  null
  
)

export default component.exports