<template>
  <div class="user-data">
    {{ 'ALL_USER_DATA_SUB_HEADER' | fromTextKey }}<br />
    {{ 'ALL_USER_DATA_SUB_HEADER_2' | fromTextKey }}
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Alt data" active>
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_CONTACT_INFO"
            :selected-user="selectedUser"
            :show-date-search="false"
            :type="entityTypesRightToBeForgotten.CONTACT_INFORMATION"
            :prepare-params="prepareContactInfoParams"
            :prepare-items="prepareContactInfoItems"
            is-disabled-delete
            url="?method=profilesAdmin.getUserData"
            table-ref="contactInfoTable"
            :show-category="true"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_GROUPS"
            :selected-user="selectedUser"
            :show-date-search="false"
            :type="entityTypesRightToBeForgotten.GROUPS"
            :prepare-params="prepareGroupParams"
            :prepare-items="prepareGroupItems"
            :per-page="9999"
            url="?method=groupsAdmin.getMemberships"
            table-ref="groupsTable"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_POSTS"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.POSTS"
            :prepare-params="preparePostParams"
            :prepare-items="preparePostItems"
            url="?method=postsAdmin.getAllPostsForCreator"
            table-ref="postsTable"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_EVENTS"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.EVENTS"
            :prepare-params="prepareEventParams"
            :prepare-items="prepareEventItems"
            :parent="parentTypes.CALENDAR"
            url="?method=calendarAdmin.getEventsByProfileId"
            table-ref="eventsTable"
            :show-category="true"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_THREADS"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.THREADS"
            :prepare-params="prepareThreadParams"
            :prepare-items="prepareThreadItems"
            url="?method=messagingAdmin.getThreads"
            table-ref="threadsTable"
            :show-sensitivity="true"
            :enabled-fields="['sensitivity']"
          />
          <message-revisions-table :selected-user="selectedUser" />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_MEDIA"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.MEDIA"
            :prepare-params="prepareMediaParams"
            :prepare-items="prepareMediaItems"
            url="?method=galleryAdmin.getMediaByInstitutionProfileId"
            table-ref="mediaTable"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_DOCUMENTS"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.DOCUMENTS"
            :prepare-params="prepareDocumentParams"
            :prepare-items="prepareDocumentItems"
            url="?method=documentsAdmin.getSecureDocumentsRegardingInstitutionProfile"
            table-ref="documentsTable"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_COMMENTS"
            :selected-user="selectedUser"
            :type="entityTypesRightToBeForgotten.COMMENTS"
            :prepare-params="prepareCommentsParams"
            :prepare-items="prepareCommentsItems"
            url="?method=commentsAdmin.getAllCommentsForUser"
            table-ref="commentsTable"
          />
          <all-user-data-table
            header-text="ALL_USER_DATA_HEADER_TEXT_SUGGESTED_NAMES"
            :selected-user="selectedUser"
            :show-date-search="false"
            :type="entityTypesRightToBeForgotten.PICKUP_RESPONSIBLES"
            :prepare-params="prepareSuggestedNameParams"
            :prepare-items="prepareSuggestedNameItems"
            is-disabled-delete
            url="?method=presence.getSuggestedNamesForPickupChild"
            table-ref="suggestedNamesTable"
            :show-creator="false"
          />
        </b-tab>
        <b-tab :title="exportAndDeleteTitleTab" @click="initRtbfRequests()">
          <aula-table
            ref="requestsForRTBF"
            :per-page="100"
            :items="filteredRequests"
            :total-rows="filteredRequests.length"
            :fields="fields"
            :responsive="true"
            table-ref="requestsForRTBF"
            empty-table-text="USERS_WARNING_ZERO_RESULTS_FROM_SEARCH"
            empty-table-filtered-text="USERS_WARNING_ZERO_RESULTS_FROM_SEARCH"
            :show-pagination="true"
            @downloadRequest="downloadRequest"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <media-detail v-if="$route.path.includes('medie')" :parent="parentTypes.USERDATA" :album="medias" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import moment from 'moment-timezone';
import { userDataTableCategory } from '../../../shared/enums/userDataTableCategory';
import { entityTypesRightToBeForgotten } from '../../../shared/enums/entityTypesRightToBeForgotten';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import MediaDetail from '../../../src/components/gallery/MediaDetail';
import AllUserDataTable from './AllUserDataTable';
import MessageRevisionsTable from './MessageRevisionsTable';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { providerKeyEnum } from '../../../shared/enums/providerKeyEnum';

export default {
  components: {
    AllUserDataTable,
    MessageRevisionsTable,
    MediaDetail,
  },
  inject: {
    getInstitutionProfile: providerKeyEnum.institutionProfile,
  },
  data() {
    return {
      moment: moment,
      parentTypes: parentTypes,
      entityTypesRightToBeForgotten: entityTypesRightToBeForgotten,
      fields: {
        periodFrom: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_PERIOD_FROM'),
        },
        periodTo: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_PERIOD_TO'),
        },
        creatorName: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_CREATOR_NAME'),
        },
        entityType: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_ENTITY_TYPE'),
        },
        operationType: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_OPERATION_TYPE'),
        },
        status: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_STATUS'),
        },
        actions: {
          label: this.$options.filters.fromTextKey('RTBF_REQUESTS_ACTIONS'),
          type: 'action',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      medias: types.GET_MEDIAS_OF_INSTITUTION_PROFILE_ID,
      requestsForRTBF: types.GET_RTBF_REQUESTS,
    }),
    selectedUser() {
      return this.getInstitutionProfile();
    },
    exportAndDeleteTitleTab() {
      return Vue.filter('fromTextKey')('EXPORTS') + '/' + Vue.filter('fromTextKey')('DELETION');
    },
    filteredRequests() {
      const requestsForRTBF = this.requestsForRTBF;
      for (const request of requestsForRTBF) {
        if (request.processed) {
          if (request.failed) {
            request.status = this.$options.filters.fromTextKey('RTBF_STATUS_FAILED');
          } else {
            request.status = this.$options.filters.fromTextKey('RTBF_STATUS_DONE');
          }
        } else {
          request.status = this.$options.filters.fromTextKey('RTBF_STATUS_BEING_PROCESSED');
        }
        request.periodFrom = moment(request.periodFrom).format('DD-MM-YYYY HH:mm');
        request.periodTo = moment(request.periodTo).format('DD-MM-YYYY HH:mm');
        if (request.processed && !request.failed && request.operationType == 'Export') {
          request.actions = [
            {
              method: 'downloadRequest',
              icon: 'icon-Aula_download',
              variant: 'link',
            },
          ];
        }
        if (request.operationType == 'Delete') {
          request.operationType = this.$options.filters.fromTextKey('RTBF_OPERATION_DELETE');
        } else if (request.operationType == 'Export') {
          request.operationType = this.$options.filters.fromTextKey('RTBF_OPERATION_EXPORT');
        }
        request.entityType = this.$options.filters.fromTextKey('RTBF_ENTITY_' + request.entityType.toUpperCase());
      }
      return requestsForRTBF;
    },
  },
  methods: {
    ...mapMutations({
      mutateMedia: types.MUTATE_MEDIAS_OF_INSTITUTION_PROFILE_ID,
    }),
    ...mapActions({
      loadRTBFRequests: types.LOAD_RTBF_REQUESTS,
    }),
    getDisplayTitleForDeletedItem(itemTitle) {
      const displayTitle = itemTitle == null ? '' : itemTitle;
      return displayTitle + ' (' + this.$options.filters.fromTextKey('USER_DATA_IS_DELETED') + ')';
    },
    downloadRequest(row) {
      window.location = row.item.fileLink.url;
    },
    prepareGroupParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareGroupItems(response) {
      // GROUPS
      const tableItems = [];
      if (response.data.data != null && response.data.data.memberships != null) {
        for (const group of response.data.data.memberships) {
          let createdBy = group.createdByName;
          if (group.groupType == groupTypes.UNI) {
            createdBy = 'UNI-login oplysning';
          }
          this.addItemToList(
            tableItems,
            moment(group.createdAt).format('DD-MM-YYYY'),
            userDataTableCategory.GROUP,
            group.groupName,
            group.groupDescription,
            createdBy,
            '2',
            'group-' + group.id,
            'group',
            '',
            group
          );
        }
      }
      return tableItems;
    },
    preparePostParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    preparePostItems(response) {
      // POSTS
      const tableItems = [];
      if (response.data.data != null) {
        for (const post of response.data.data.posts) {
          const postGroups = post.sharedWithGroups
            .map(group => {
              const portalRoleTexts = [];
              if (group.portalRoles != null) {
                for (const portalRole of group.portalRoles) {
                  portalRoleTexts.push(
                    this.$options.filters.fromTextKey('GROUP_LABEL_TYPE_' + portalRole.toUpperCase())
                  );
                }
              }
              return portalRoleTexts.length > 0 ? group.name + ' (' + portalRoleTexts.join(', ') + ')' : group.name;
            })
            .join(', ');
          const displayTitle = post.isDeleted
            ? this.getDisplayTitleForDeletedItem(post.title + ' (' + postGroups + ')')
            : post.title + ' (' + postGroups + ')';
          this.addItemToList(
            tableItems,
            moment(post.timestamp).format('DD-MM-YYYY'),
            userDataTableCategory.POST,
            displayTitle,
            post.content.html,
            post.ownerProfile.firstName + ' ' + post.ownerProfile.lastName,
            '2',
            'post-' + post.id,
            'post',
            '',
            post
          );
        }
      }
      return tableItems;
    },
    prepareEventParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareEventItems(response) {
      // EVENTS
      const tableItems = [];
      if (response.data.data != null) {
        for (const event of response.data.data.events) {
          let description = '';
          if (event.description != null) {
            if (event.description.html != null && event.description.html != '') {
              description = event.description.html;
            } else if (event.description.html == null && event.description != '') {
              description = event.description;
            } else {
              description = '';
            }
          }
          if (event.allDay) {
            if (event.startDateTime) {
              event.startDateTime = event.startDateTime.split('T')[0];
            }
            if (event.endDateTime) {
              event.endDateTime = event.endDateTime.split('T')[0];
            }
          }
          let startAndEndTime =
            event.endDateTime != null
              ? moment(event.startDateTime).format('DD-MM-YYYY') +
                ' - ' +
                moment(event.endDateTime).format('DD-MM-YYYY')
              : moment(event.startDateTime).format('DD-MM-YYYY') + ' - Ingen slutdato';
          if (event.creatorInstProfileId == this.selectedUser.id) {
            let category = userDataTableCategory.EVENT;
            let title = event.title;
            let creator = event.creatorName;
            if (event.type == eventTypeEnum.HOLIDAY || event.type == eventTypeEnum.PRESENCE_HOLIDAY) {
              startAndEndTime =
                event.endDateTime != null
                  ? moment(event.startDateTime).utc().format('DD-MM-YYYY') +
                    ' - ' +
                    moment(event.endDateTime).utc().format('DD-MM-YYYY')
                  : moment(event.startDateTime).utc().format('DD-MM-YYYY') + ' - Ingen slutdato';
              category = userDataTableCategory.HOLIDAY;
              const interval = moment(event.endDateTime).diff(moment(event.startDateTime), 'days') + 1;
              title = 'Ferie for ' + interval + ' dage.';
              creator = '';
            }
            const displayTitle = event.isDeleted ? this.getDisplayTitleForDeletedItem(title) : title;
            this.addItemToList(
              tableItems,
              moment(event.createdDateTime).format('DD-MM-YYYY'),
              category,
              displayTitle,
              description,
              creator,
              '2',
              'event-' + event.id,
              'event',
              '',
              event,
              startAndEndTime
            );
          } else {
            const displayTitle = event.isDeleted ? this.getDisplayTitleForDeletedItem(event.title) : event.title;
            this.addItemToList(
              tableItems,
              moment(event.createdDateTime).format('DD-MM-YYYY'),
              userDataTableCategory.INVITATION,
              displayTitle,
              description,
              event.creatorName,
              '2',
              'invitation-' + event.id,
              'invitation',
              '',
              event,
              startAndEndTime
            );
          }
        }
      }
      return tableItems;
    },
    prepareThreadParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareThreadItems(response) {
      // MESSAGES
      const tableItems = [];
      if (response.data.data != null) {
        for (const subscription of response.data.data.threads) {
          const participantString = subscription.recipients.map(rec => rec.fullName).join(', ');
          const displayTitle = subscription.isThreadOrSubscriptionDeleted
            ? this.getDisplayTitleForDeletedItem(subscription.subject)
            : subscription.subject;
          this.addItemToList(
            tableItems,
            moment(subscription.startedTime).format('DD-MM-YYYY'),
            userDataTableCategory.SUBSCRIPTION,
            displayTitle,
            participantString,
            subscription.creator.fullName,
            subscription.sensitive ? 3 : 2,
            'subscription-' + subscription.id,
            'subscription',
            '',
            subscription
          );
        }
      }
      return tableItems;
    },
    prepareMediaParams(ctx, fields, startDate, endDate) {
      const params = {
        institutionProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        index: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareMediaItems(response) {
      // MEDIA
      const tableItems = [];
      if (response.data.data != null) {
        this.mutateMedia(response.data.data);
        for (const media of response.data.data.results) {
          const descriptionParam = [
            {
              variant: 'primary',
              method: 'openMediaDetail',
              textkey: 'TABLE_LINK_TO_MEDIA',
              url: media.file.url,
            },
          ];
          media.descriptionType = 'action';
          const displayTitle = media.isDeleted ? this.getDisplayTitleForDeletedItem(media.title) : media.title;
          this.addItemToList(
            tableItems,
            moment(media.file.created).format('DD-MM-YYYY'),
            userDataTableCategory.MEDIA,
            displayTitle,
            descriptionParam,
            media.creator.name,
            2,
            'media-' + media.id,
            'media',
            '',
            media
          );
        }
      }
      return tableItems;
    },
    prepareDocumentParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareDocumentItems(response) {
      // DOCUMENTS
      const tableItems = [];
      if (response.data.data != null) {
        for (const document of response.data.data.documents) {
          const description = [document.category];
          if (document.associatedInstitutionProfiles) {
            for (const profile of document.associatedInstitutionProfiles) {
              description.push(profile.name);
            }
          }
          const displayTitle = document.isDeleted ? this.getDisplayTitleForDeletedItem(document.title) : document.title;
          this.addItemToList(
            tableItems,
            moment(document.createdAt).format('DD-MM-YYYY'),
            userDataTableCategory.DOCUMENT,
            displayTitle,
            description.join(', '),
            document.creator.name,
            '3',
            document.id,
            'document',
            '',
            document
          );
        }
      }
      return tableItems;
    },
    prepareCommentsParams(ctx, fields, startDate, endDate) {
      const params = {
        instProfileId: this.selectedUser.id,
        start: startDate,
        end: endDate,
        startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
        limit: ctx.perPage,
      };
      return params;
    },
    prepareCommentsItems(response) {
      // COMMENTS
      const tableItems = [];
      if (response.data.data != null) {
        for (const comment of response.data.data.comments) {
          const displayTitle = comment.isDeleted
            ? this.getDisplayTitleForDeletedItem(comment.content)
            : comment.content;
          this.addItemToList(
            tableItems,
            moment(comment.createdAt).format('DD-MM-YYYY'),
            userDataTableCategory.COMMENTS,
            comment.title,
            displayTitle,
            comment.creator.name,
            '3',
            comment.id,
            'comment',
            '',
            comment
          );
        }
      }
      return tableItems;
    },
    prepareSuggestedNameParams() {
      const params = {
        uniStudentId: this.selectedUser.id,
      };
      return params;
    },
    prepareSuggestedNameItems(response) {
      // SUGGESTED NAMES
      const tableItems = [];
      if (response.data.data != null) {
        const suggestedPickUpNames = response.data.data;
        if (suggestedPickUpNames.length > 0) {
          const pickupNames = suggestedPickUpNames.map(pickup => pickup.pickupName).join(', ');
          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.PICKUP,
            this.$options.filters.fromTextKey('PRESENCE_SUGGESTED_PICKUP'),
            pickupNames,
            '',
            2,
            'pickup-' + this.selectedUser.id,
            'pickup',
            '',
            { uniStudentId: this.selectedUser.id }
          );
        }
      }
      return tableItems;
    },
    prepareContactInfoParams() {
      const params = {
        institutionProfileIds: [this.selectedUser.id],
      };
      return params;
    },
    prepareContactInfoItems(response) {
      // CONTACT INFO
      const tableItems = [];
      if (response.data.data != null) {
        const contactInfo = response.data.data;
        this.addItemToList(
          tableItems,
          '',
          userDataTableCategory.ADDITIONAL_DATA,
          'Nulstil svar',
          '',
          '',
          '2',
          'additional-data',
          'additional-data',
          '',
          this.selectedUser
        );
        this.addItemToList(
          tableItems,
          '',
          userDataTableCategory.CONSENTS,
          'Nulstil svar',
          '',
          '',
          '2',
          'consents',
          'consents',
          '',
          this.selectedUser
        );

        if (contactInfo.length > 0) {
          // AULA CONTACT INFORMATION
          if (contactInfo[0].contactInformation != null) {
            if (contactInfo[0].contactInformation.homePhoneNumber != null) {
              this.addItemToList(
                tableItems,
                moment(contactInfo[0].contactInformation.lastUpdateHomePhoneNumber).isValid()
                  ? moment(contactInfo[0].contactInformation.lastUpdateHomePhoneNumber).format('DD-MM-YYYY')
                  : '',
                userDataTableCategory.CONTACT_INFO,
                'Hjemme tlf. nummer',
                contactInfo[0].contactInformation.homePhoneNumber,
                '',
                '2',
                'aula-home-phone',
                'aula-profile',
                'home-phone',
                this.selectedUser
              );
            }

            if (contactInfo[0].contactInformation.workPhoneNumber != null) {
              this.addItemToList(
                tableItems,
                moment(contactInfo[0].contactInformation.lastUpdateWorkPhoneNumber).isValid()
                  ? moment(contactInfo[0].contactInformation.lastUpdateWorkPhoneNumber).format('DD-MM-YYYY')
                  : '',
                userDataTableCategory.CONTACT_INFO,
                'Arbejds tlf. nummer',
                contactInfo[0].contactInformation.workPhoneNumber,
                '',
                '2',
                'aula-work-phone',
                'aula-profile',
                'work-phone',
                this.selectedUser
              );
            }

            if (contactInfo[0].contactInformation.mobilePhoneNumber != null) {
              this.addItemToList(
                tableItems,
                moment(contactInfo[0].contactInformation.lastUpdateMobilePhoneNumber).isValid()
                  ? moment(contactInfo[0].contactInformation.lastUpdateMobilePhoneNumber).format('DD-MM-YYYY')
                  : '',
                userDataTableCategory.CONTACT_INFO,
                'Mobil tlf. nummer',
                contactInfo[0].contactInformation.mobilePhoneNumber,
                '',
                '2',
                'aula-mobile-phone',
                'aula-profile',
                'mobile-phone',
                this.selectedUser
              );
            }

            if (contactInfo[0].contactInformation.emailAddress != null) {
              this.addItemToList(
                tableItems,
                moment(contactInfo[0].contactInformation.lastUpdateEmailAddress).isValid()
                  ? moment(contactInfo[0].contactInformation.lastUpdateEmailAddress).format('DD-MM-YYYY')
                  : '',
                userDataTableCategory.CONTACT_INFO,
                'E-mail',
                contactInfo[0].contactInformation.emailAddress,
                '',
                '2',
                'aula-email',
                'aula-profile',
                'email',
                this.selectedUser
              );
            }
          }
          if (contactInfo[0].description != null) {
            this.addItemToList(
              tableItems,
              moment().format('DD-MM-YYYY'),
              userDataTableCategory.CONTACT_INFO,
              'Beskrivelse',
              contactInfo[0].description,
              '',
              '2',
              'aula-description',
              'aula-profile',
              'description',
              this.selectedUser
            );
          }
          if (contactInfo[0].internalProfilePicture != null) {
            this.addItemToList(
              tableItems,
              moment(contactInfo[0].internalProfilePicture.updatedAt).format('DD-MM-YYYY'),
              userDataTableCategory.CONTACT_INFO,
              'Profilbillede',
              contactInfo[0].internalProfilePicture.key,
              contactInfo[0].internalProfilePicture.creatorName,
              '2',
              'aula-photo',
              'aula-profile',
              'photo',
              this.selectedUser
            );
          }
          if (contactInfo[0].externalProfilePicture != null) {
            this.addItemToList(
              tableItems,
              moment(contactInfo[0].externalProfilePicture.updatedAt).format('DD-MM-YYYY'),
              userDataTableCategory.CONTACT_INFO,
              'Profilbillede',
              contactInfo[0].externalProfilePicture.key,
              'Eksternt profilbillede',
              '2',
              'uni-photo',
              'aula-profile',
              'uni-photo',
              this.selectedUser
            );
          }
          // UNI LOGIN CONTACT INFORMATION
          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Navn',
            contactInfo[0].uniPerson.firstName + ' ' + contactInfo[0].uniPerson.familyName,
            'UNI-login oplysning',
            '2',
            'uni-name',
            'uni-profile',
            'name',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Fødselsdag',
            moment(contactInfo[0].uniPerson.birthDay).format('DD-MM-YYYY'),
            'UNI-login oplysning',
            '2',
            'uni-birthday',
            'uni-profile',
            'birthday',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Køn',
            contactInfo[0].uniPerson.gender,
            'UNI-login oplysning',
            '2',
            'uni-gender',
            'uni-profile',
            'gender',
            this.selectedUser
          );

          const postalCode =
            contactInfo[0].uniPerson.address && contactInfo[0].uniPerson.address.postalCode
              ? contactInfo[0].uniPerson.address.postalCode
              : Vue.filter('fromTextKey')('UNKNOW');
          const street =
            contactInfo[0].uniPerson.address && contactInfo[0].uniPerson.address.street
              ? contactInfo[0].uniPerson.address.street
              : Vue.filter('fromTextKey')('UNKNOW');
          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Adresse',
            'Postnr.: ' + postalCode + '<br>Gade: ' + street,
            'UNI-login oplysning',
            '2',
            'uni-address',
            'uni-profile',
            'address',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'E-mail',
            contactInfo[0].uniPerson.emailAddress,
            'UNI-login oplysning',
            '2',
            'uni-email',
            'uni-profile',
            'email',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Hjemme tlf. nummer',
            contactInfo[0].uniPerson.homePhoneNumber,
            'UNI-login oplysning',
            '2',
            'uni-home-phone',
            'uni-profile',
            'home-phone',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Arbejds tlf. nummer',
            contactInfo[0].uniPerson.workPhoneNumber,
            'UNI-login oplysning',
            '2',
            'uni-work-phone',
            'uni-profile',
            'work-phone',
            this.selectedUser
          );

          this.addItemToList(
            tableItems,
            moment().format('DD-MM-YYYY'),
            userDataTableCategory.MASTER_DATA,
            'Mobil tlf. nummer',
            contactInfo[0].uniPerson.mobilePhoneNumber,
            'UNI-login oplysning',
            '2',
            'uni-mobile-phone',
            'uni-profile',
            'mobile-phone',
            this.selectedUser
          );
        }
      }
      return tableItems;
    },
    addItemToList(
      itemsArray,
      creationDate,
      category,
      title,
      description,
      contactPerson,
      sensitivity,
      itemId,
      type,
      property,
      object,
      eventDate = null
    ) {
      itemsArray.push({
        creationDate: creationDate,
        category: category,
        title: title,
        description: description,
        eventDate: eventDate,
        contactPerson: contactPerson,
        sensitivity: sensitivity,
        actions: [{ method: 'deleteUserData', icon: 'icon-Aula_bin', variant: 'link' }],
        itemId: itemId,
        type: type,
        property: property,
        object: object,
      });
    },
    initRtbfRequests() {
      this.loadRTBFRequests({
        instProfileId: this.selectedUser.id,
        start: moment().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        end: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        processed: true,
      });
    },
  },
  mounted() {
    this.initRtbfRequests();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';

/deep/.aula-table th {
  .icon-Aula_down-arrow {
    right: 10px;
  }
  &.creation-date {
    min-width: 210px;
  }
}
.show-hide {
  background-color: $color-white;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
}
</style>
