<template>
  <div class="role-and-right-assignment-container d-flex">
    <b-dropdown
      v-if="unassignedRoles.length > 0"
      ref="dropdownAssignRoles"
      class="mx-2 role-assignment-dropdown"
      menu-class="role-assignment-menu px-2"
      size="sm"
      :text="'USERS_BUTTON_ADD_USER_ROLES' | fromTextKey"
    >
      <b-form-input v-model="searchQuery" type="text" size="sm" :placeholder="'SEARCH_LABEL' | fromTextKey" />
      <b-form-checkbox-group v-model="selectedRoleIdsToAssign" class="roles-list my-2">
        <template v-for="(userRole, i) in unassignedRoles">
          <b-form-checkbox
            v-show="getCanShowRole(userRole)"
            :key="i"
            :value="userRole.id"
            @change="onUserRoleChanged(userRole)"
          >
            {{ userRole.name }}
          </b-form-checkbox>
          <template v-for="(subRole, j) in getUnassignedSubRoles(userRole.subRoles)">
            <b-form-checkbox
              v-show="getCanShowRole(subRole)"
              :key="i + '-' + j"
              class="subRole"
              :value="subRole.id"
              @change="onSubRoleChanged(userRole)"
            >
              {{ subRole.name }}
            </b-form-checkbox>
          </template>
        </template>
      </b-form-checkbox-group>
      <div class="d-flex justify-content-center">
        <AulaButton variant="primary" :disabled="isLoading" class="action-btn" @click="onAssignRoles">
          <AulaSpinner v-if="isLoading" class="spinner" />
          {{ 'BUTTON_SAVE' | fromTextKey }}
        </AulaButton>
      </div>
    </b-dropdown>
    <b-dropdown
      v-if="assignedRoles.length > 0"
      ref="dropdownRemoveRoles"
      menu-class="role-assignment-menu px-2"
      size="sm"
      :text="'USERS_BUTTON_REMOVE_USER_ROLES' | fromTextKey"
    >
      <b-form-input v-model="searchQuery" type="text" :placeholder="'SEARCH_LABEL' | fromTextKey" />
      <b-form-checkbox-group v-model="selectedRoleIdsToRemove" class="roles-list my-2">
        <template v-for="(groupRole, i) in assignedRoles">
          <b-form-checkbox v-show="getCanShowRole(groupRole)" :key="i" :value="groupRole.id">
            {{ groupRole.name }}
          </b-form-checkbox>
        </template>
      </b-form-checkbox-group>
      <div class="d-flex justify-content-center">
        <AulaButton class="action-btn" variant="primary" :disabled="isLoading" @click="onRemoveRoles">
          <AulaSpinner v-if="isLoading" class="spinner" />
          {{ 'BUTTON_SAVE' | fromTextKey }}
        </AulaButton>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import { providerKeyEnum } from '../enums/providerKeyEnum';
import AulaButton from './AulaButton.vue';

export default {
  name: 'RoleAndRightAssignments',
  components: { AulaButton },
  inject: {
    getSelectedInstitution: providerKeyEnum.selectedInstitution,
    getRoleDefinitions: providerKeyEnum.roleDefinitions,
    getSelectedUserAssignedRoles: providerKeyEnum.selectedUserAssignedRoles,
    assignRolesToUser: providerKeyEnum.assignRolesToUser,
  },
  data() {
    return {
      isLoading: false,
      searchQuery: '',
      selectedRoleIdsToAssign: [],
      selectedRoleIdsToRemove: [],
    };
  },
  computed: {
    selectedInstitution() {
      return this.getSelectedInstitution();
    },
    roleDefinitions() {
      return this.getRoleDefinitions();
    },
    assignedRoles() {
      return this.getSelectedUserAssignedRoles();
    },
    unassignedRoles() {
      const assignedRoleIds = this.assignedRoles.map(role => role.id);
      return this.roleDefinitions.filter(definition => assignedRoleIds.includes(definition.id) === false);
    },
  },
  methods: {
    getCanShowRole(role) {
      return !this.searchQuery || role.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    getUnassignedSubRoles(subRoles) {
      if (!subRoles) {
        return [];
      }
      const assignedRoleIds = this.assignedRoles.map(role => role.id);
      return subRoles.filter(subRole => assignedRoleIds.includes(subRole.id) === false);
    },
    onUserRoleChanged(userRole) {
      if (!userRole.subRoles) {
        return;
      }
      if (this.selectedRoleIdsToAssign.includes(userRole.id)) {
        userRole.subRoles.forEach(subRole => {
          if (!this.selectedRoleIdsToAssign.includes(subRole.id)) {
            this.selectedRoleIdsToAssign.push(subRole.id);
          }
        });
      } else {
        const subRoleIds = userRole.subRoles.map(subRole => subRole.id);
        this.selectedRoleIdsToAssign = this.selectedRoleIdsToAssign.filter(id => subRoleIds.includes(id) === false);
      }
    },
    onSubRoleChanged(userRole) {
      const assignableSubRoles = this.getUnassignedSubRoles(userRole.subRoles);
      if (assignableSubRoles.every(subRole => this.selectedRoleIdsToAssign.includes(subRole.id))) {
        this.selectedRoleIdsToAssign.push(userRole.id);
      } else {
        this.selectedRoleIdsToAssign = this.selectedRoleIdsToAssign.filter(roleId => userRole.id !== roleId);
      }
    },
    async onAssignRoles() {
      await this.setRoleAssignments();
      this.$refs.dropdownAssignRoles.visible = false;
    },
    async onRemoveRoles() {
      await this.setRoleAssignments();
      this.$refs.dropdownRemoveRoles.visible = false;
    },
    async setRoleAssignments() {
      this.isLoading = true;
      await this.assignRolesToUser({
        roleIdsToAssign: this.selectedRoleIdsToAssign,
        roleIdsToUnAssign: this.selectedRoleIdsToRemove,
      });
      this.isLoading = false;
      this.resetSelectedValues();
    },
    resetSelectedValues() {
      this.selectedRoleIdsToAssign = [];
      this.selectedRoleIdsToRemove = [];
    },
  },
};
</script>
<style scoped lang="scss">
input.form-control {
  --input-border-color: var(--color-grey-base);
}

.roles-list {
  --dropdown-menu-max-height: 400px;
}

.spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  transform: translate(-25%, -25%);
}
</style>
