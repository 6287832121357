
















































































import { PropType } from 'vue';
import { InstitutionProfileMasterDataForm } from '../../business/institutionProfileMasterDataForm';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import IconContainer from '../../../shared/components/IconContainer.vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  components: { IconContainer, Icon },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
    isEditing: { type: Boolean, default: false },
  },
  emits: ['input'],
  data() {
    return {
      contactForm: new InstitutionProfileMasterDataForm(this.profile),
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    canEdit() {
      return this.isEditing && this.profile.currentUserCanEditContactInformation;
    },
    canShowWorkPhoneNumber() {
      return this.profile.role !== portalRoles.CHILD;
    },
    canView() {
      return !this.isEditing && this.profile.currentUserCanViewContactInformation;
    },
    canEditDescription() {
      return this.isEditing && this.profile.currentUserCanEditProfileDescription;
    },
  },
  watch: {
    isEditing(value) {
      if (value) {
        this.initializeForm();
      }
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.contactForm);
    },
    initializeForm() {
      this.contactForm = new InstitutionProfileMasterDataForm(this.profile);
      if (!this.canEditDescription) {
        this.contactForm.description = null;
      }
    },
  },
};
